const data = {
    phone: '+7 (495) 255-50-00',
    phoneHref: 'tel:74952555000',

    email: 'event@ddxfitness.ru',
    emailHref: 'mailto:event@ddxfitness.ru',

    mainSite: 'ddxfitness.ru',
    mainSiteHref: 'https://www.ddxfitness.ru/?utm_source=event&utm_medium=organic&utm_campaign=fitness_camp',

    vk: 'https://vk.com/ddx_fitness',
    yt: 'https://www.youtube.com/channel/UCF8MClm91HIe3k2ieXX3Scg',
    tg: 'https://t.me/ddxfitness_official',
    dzen: 'https://dzen.ru/ddx_fitness_ru',

    ymapsScriptSrc: 'https://api-maps.yandex.ru/2.1/?apikey=9d947a9e-0ead-4e45-97f3-430486eb9c0b&lang=ru_RU',

    nethouse: 'https://events.nethouse.ru/buy_tickets/105884/',
}

export { data }
export { menuLinksDesktop, menuLinksMobile } from './header'
export { heroImage, heroImageMobile } from './hero'
export { locationSlides } from './location'
export { eventSchedules, offerPdf } from './program'
export { feedback } from './feedback'
export { questions } from './faq'
export { prevCampSlides } from './prev-camp'